import styles from './page.module.css';

import get from 'lodash/get';
import Head from 'next/head';
import { MutableRefObject, ReactElement, useContext, useEffect } from 'react';

import { GA_ID } from 'core/constants/google-analytics';
import { SearchOptions } from 'core/entities/search';
import { CommonPageContext } from 'core/services/context/context';
import { saveUtm } from 'core/utils/metrics';

import { CommonContext } from 'contexts/common';

import { HeaderBackLink, HeaderBackLinkProps } from 'components/layout/header-back-link/header-back-link';
import { Layout } from 'components/layout/layout';

interface PageProps {
  ctx: CommonPageContext;
  children?: ReactElement;

  renderLayout?(): ReactElement;

  backLink?: Optional<HeaderBackLinkProps>;
  hasSearchBar?: boolean;

  searchOptions?: SearchOptions;

  slimFooter?: boolean;
  noHeader?: boolean;
  noFooter?: boolean;
  noIndex?: boolean;
  isCentral?: boolean;
  isTransparentHead?: boolean;
  hasMobileSearchBar?: boolean;
  withoutLoading?: boolean;
  simpleHeader?: boolean;
  disableHorizontalMobileScroll?: boolean;
  imageUrl?: string;

  headerRef?: MutableRefObject<Optional<HTMLDivElement>>;
}

// eslint-disable-next-line complexity
const SeoHead = (props: PageProps) => {
  const image = props.imageUrl ? props.imageUrl : '/s/modern/logo/kvartirka-logo-vk.png';
  return (
    <Head>
      <title>{get(props.ctx, 'seo.title', '')}</title>
      <meta property='og:title' content={get(props.ctx, 'seo.title', '')} key='title' />
      <meta property='og:description' content={get(props.ctx, 'seo.description', '')} key='description' />
      <meta property='og:url' content={get(props.ctx, 'seo.canonical', '')} key='canonical' />
      <meta property='og:image' content={image} key='image' />
      <meta name='description' lang='ru' content={get(props.ctx, 'seo.description', '')} />
      <meta name='yandex-tableau-widget' content='/s/modern/manifest/yandex-tableau-widget.png, color=#ffffff' />
      <meta name='yandex-verification' content='7a1417b82eed880a' />
      <meta name='google-site-verification' content='lXIZ9WjBcAgIMSFCrGl-gVlOrJIeNp7qkMtTAblMqQo' />
      // TODO: Получать id приложений с сервера
      <meta name='apple-itunes-app' content='app-id=733790235' />
      <meta name='google-play-app' content='app-id=ru.kvartirka.android_new' />
      {props.noIndex ? <meta name='robots' content='noindex, follow' /> : null}
      {props.imageUrl && <link rel='image_src' href={props.imageUrl} />}
      <link type='image/x-icon' rel='shortcut icon' href='/favicon.ico' />
      <link
        type='image/png'
        rel='apple-touch-icon-precomposed'
        href='/s/modern/apple-touch/common/apple-touch-icon-precomposed.png'
      />
      <link
        type='image/png'
        rel='apple-touch-icon-precomposed'
        sizes='72x72'
        href='/s/modern/apple-touch/common/apple-touch-icon-72x72-precomposed.png'
      />
      <link
        type='image/png'
        rel='apple-touch-icon-precomposed'
        sizes='114x114'
        href='/s/modern/apple-touch/common/apple-touch-icon-114x114-precomposed.png'
      />
      <link
        type='image/png'
        rel='apple-touch-icon-precomposed'
        sizes='120x120'
        href='/s/modern/apple-touch/common/apple-touch-icon-120x120-precomposed.png'
      />
      <link
        type='image/png'
        rel='apple-touch-icon-precomposed'
        sizes='144x144'
        href='/s/modern/apple-touch/common/apple-touch-icon-144x144-precomposed.png'
      />
      <link
        type='image/png'
        rel='apple-touch-icon-precomposed'
        sizes='152x152'
        href='/s/modern/apple-touch/common/apple-touch-icon-152x152-precomposed.png'
      />
      <link
        type='image/png'
        rel='apple-touch-icon-precomposed'
        sizes='192x192'
        href='/s/modern/apple-touch/common/apple-touch-icon-192x192-precomposed.png'
      />
      <link rel='manifest' href='/s/modern/manifest/manifest.json' />
      <script async src={`https://www.googletagmanager.com/gtag/js?id=${GA_ID}`} />
      <script
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${GA_ID}');
          `
        }}
      />
    </Head>
  );
};

export const Page = (props: PageProps) => {
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { context, setContext } = useContext(CommonContext);
  useEffect(() => {
    if (!context && props.ctx) {
      setContext(props.ctx);
    }
  }, [context, props.ctx, setContext]);

  useEffect(() => {
    if (context && context.host) {
      saveUtm(context.host);
    }
  }, [context]);

  return (
    <>
      <SeoHead {...props} />
      {props.renderLayout ? (
        props.renderLayout()
      ) : (
        // Если передаем "props.backLink", то ссылка отображается в хедере на десктопе, и под хедером на мобильном
        <Layout
          mainHost={props.ctx.host as string}
          context={props.ctx}
          backLink={props.backLink}
          hasSearchBar={props.hasSearchBar}
          searchOptions={props.searchOptions}
          slimFooter={props.slimFooter}
          noFooter={props.noFooter}
          noHeader={props.noHeader}
          isCentral={props.isCentral}
          isTransparentHead={props.isTransparentHead}
          hasMobileSearchBar={props.hasMobileSearchBar}
          headerRef={props.headerRef}
          withoutLoading={props.withoutLoading}
          simpleHeader={props.simpleHeader}
          disableHorizontalMobileScroll={props.disableHorizontalMobileScroll}
        >
          <>
            {props.backLink && !props.ctx.isApp && !props.hasMobileSearchBar && (
              <div className={styles.mobileBackLink}>
                <HeaderBackLink {...props.backLink} />
              </div>
            )}
            {props.children}
          </>
        </Layout>
      )}
    </>
  );
};
