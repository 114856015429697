import cookie from 'js-cookie';
import get from 'lodash/get';
import qs from 'qs';

import { GTAG_ID } from 'core/constants/google-analytics';

declare global {
  interface Window {
    yandexCounter: {
      reachGoal: (yandexTarget: string) => void;
    };
  }
}

export type ValueType = string | number | boolean;

export interface Description {
  [key: string]: ValueType;
}

export interface Event {
  category: string;
  event: string;
  description?: DescriptionType;
  value?: ValueType;
}

export interface Counters {
  yandexTarget: string;
  virtualPath: string;
}

export type DescriptionType = Description | string;

export interface BookingEvent {
  event: string;
  description: DescriptionType;
  value?: number;
}

export function serializeDescription(description: DescriptionType) {
  if (typeof description === 'object') {
    return (
      Object.keys(description)
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        .map((key) => `${key}=${description[key]}`)
        .join('; ')
    );
  }
  return description;
}

export function sendEvent(category: string, event = '', description: DescriptionType = '', value: ValueType = '') {
  // console.log('sendEvent(category, event, description, value)', category, event, description, value);
  if (typeof window === 'object' && typeof window.ga === 'function') {
    if (description) {
      if (value) {
        window.ga(`${GTAG_ID}.send`, 'event', category, event, serializeDescription(description), value);
      } else {
        window.ga(`${GTAG_ID}.send`, 'event', category, event, serializeDescription(description));
      }
    } else {
      window.ga(`${GTAG_ID}.send`, 'event', category, event);
    }
  }
}

export const sendEventWithCounters = (event: Event, counters: Counters) => {
  if (window.yandexCounter && counters.yandexTarget) {
    window.yandexCounter.reachGoal(counters.yandexTarget);
  }

  if (event) {
    sendEvent(
      get(event, 'category', ''),
      get(event, 'event', ''),
      get(event, 'description', ''),
      get(event, 'value', '')
    );
  }

  if (counters.virtualPath && typeof window.ga === 'function') {
    window.ga('send', 'pageview', counters.virtualPath);
  }
};

export const sendBookingEvent = (bookingEvent: BookingEvent) =>
  sendEventWithCounters(
    {
      category: 'BookingProcess',
      event: bookingEvent.event,
      description: bookingEvent.description,
      value: bookingEvent.value
    },
    {
      yandexTarget: bookingEvent.event,
      virtualPath: `/booking/${bookingEvent.event}/`
    }
  );

export function saveUtm(domain: string) {
  const getParams = qs.parse(location.search.substring(1));
  const utmParams: Record<string, string> = {};
  let utmRewrite = false;
  for (const param of Object.keys(getParams)) {
    if (param.toLowerCase().search('utm') >= 0) {
      utmRewrite = true;
      utmParams[param] = <string>getParams[param];
    }
  }

  if (utmRewrite) {
    cookie.remove('utm');
    cookie.set('utm', utmParams as unknown as string, { expires: 365, domain });
  }
}

export function pushEvent(event: Record<string, string | number | boolean>) {
  if (typeof window !== 'undefined') {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(event);
  }
}
