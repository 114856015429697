import styles from './info-link-item.module.css';

import { useContext } from 'react';

import { InfoPageMeta } from 'core/entities/info';

import { AppParams } from 'controllers/app/app-params';

import { CommonContext } from 'contexts/common';

import CustomLink from 'components/common/link/link';

interface InfoLinkItemProps {
  link: InfoPageMeta;
}

export const InfoLinkItem = ({ link }: InfoLinkItemProps) => {
  const { context } = useContext(CommonContext);

  let appParams;
  if (context?.isApp) {
    appParams = new AppParams().add(AppParams.title, link.title).add(AppParams.screensBack, 1);
  }

  return (
    <li key={link.slug} className={styles.root}>
      <CustomLink href={`/info/${link.slug}`} appParams={appParams}>
        {link.title}
      </CustomLink>
    </li>
  );
};
