import styles from './breadcrumb-item.module.css';

import { useContext } from 'react';

import { Breadcrumb } from 'core/entities/breadcrumb';

import { AppParams } from 'controllers/app/app-params';

import { CommonContext } from 'contexts/common';

import CustomLink from 'components/common/link/link';

interface BreadcrumbItemProps {
  breadcrumb: Breadcrumb;
  index: number;
}

export const BreadcrumbItem = ({ breadcrumb, index }: BreadcrumbItemProps) => {
  const { context } = useContext(CommonContext);

  let appParams;
  if (context?.isApp) {
    appParams = new AppParams().add(AppParams.title, breadcrumb.title).add(AppParams.screensBack, 1);
  }

  return (
    <li
      className={styles.root}
      key={breadcrumb.title}
      itemScope
      itemProp='itemListElement'
      itemType='https://schema.org/ListItem'
    >
      {breadcrumb.slug && (
        <CustomLink href={breadcrumb.slug} variant='black' underlined external appParams={appParams} itemProp>
          <span itemProp='name'>{breadcrumb.title}</span>
        </CustomLink>
      )}
      {!breadcrumb.slug && <span itemProp='name'>{breadcrumb.title}</span>}
      <meta itemProp='position' content={(index + 1).toString()} />
    </li>
  );
};
