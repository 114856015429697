import styles from './info.module.css';

import classNames from 'classnames';
import get from 'lodash/get';
import { GetServerSideProps } from 'next';
import React from 'react';

import { Breadcrumb } from 'core/entities/breadcrumb';
import { fetchPageContext, PageContextOptions } from 'core/services/context';
import { CommonPageContext, InfoPageContext } from 'core/services/context/context';
import { InfoPageService } from 'core/services/context/info';
import { getQueryParamsWithUrl } from 'core/utils/filters/search-filter';

import { Breadcrumbs } from 'components/common/breadcrumbs/breadcrumbs';
import { HTMLBlock } from 'components/common/html-block/html-block';
import { Text } from 'components/common/text/text';
import { InfoLinksList } from 'components/info-page/info-links-list/info-links-list';
import { Page } from 'components/page/page';

interface InfoSectionPageProps {
  context: {
    common: CommonPageContext;
    extra: InfoPageContext;
  };
}

const InfoPage: React.FunctionComponent<InfoSectionPageProps> = (props) => {
  const makeBreadcrumbs = (): Array<Breadcrumb> => {
    const parents: Array<Breadcrumb> = props.context.extra.page.parents.map((parent) => {
      return {
        ...parent,
        slug: `/info/${parent.slug}`
      };
    });

    if (!parents) {
      return [];
    }

    const rootBreadcrumb: Array<Breadcrumb> = [{ title: 'Помощь', slug: '/info' }];
    const currentPageBreadcrumb: Array<Breadcrumb> = [{ title: props.context.extra.page.current_page.title }];

    return rootBreadcrumb.concat(parents).concat(currentPageBreadcrumb);
  };

  return (
    <Page
      ctx={{
        ...props.context.common,
        seo: {
          ...props.context.common.seo,
          title: props.context.extra.page.current_page.seo_title
            ? props.context.extra.page.current_page.seo_title
            : props.context.extra.page.current_page.title,
          description: props.context.extra.page.current_page.description
        }
      }}
    >
      <div className={classNames(styles.info, { [styles.app]: props.context.common.isApp })}>
        <div className={styles.infoWrapper}>
          <Text As='h1' size={28} desktopSize={36}>
            {props.context.extra.page.current_page.headline
              ? props.context.extra.page.current_page.headline
              : props.context.extra.page.current_page.title}
          </Text>
          <HTMLBlock className={styles.content} html={props.context.extra.page.content} />
          <InfoLinksList links={props.context.extra.page.children} />
        </div>

        {makeBreadcrumbs().length && (
          <div className={styles.breadcrumbs}>
            <Breadcrumbs breadcrumbs={makeBreadcrumbs()} />
          </div>
        )}
      </div>
    </Page>
  );
};

export const getServerSideProps: GetServerSideProps = async (context) => {
  const { res } = context;
  const mainHost = process.env.NEXT_PUBLIC_MAIN_HOST || '';
  const correlationID = get(context, 'req.headers.x-request-id', '') as string;
  const cookies = get(context, 'req.headers.cookie', '');
  const accept = get(context, 'req.headers.accept', '');
  const slug = context.params ? (context.params.slug as string) : '';
  const query = getQueryParamsWithUrl(context);

  const options: PageContextOptions<InfoPageContext> = {
    path: '/',
    host: mainHost,
    correlationID,
    cookies,
    authRequired: false,
    query,
    accept,
    extra: (accessToken: Optional<string>): Promise<InfoPageContext> => {
      const infoPageService = new InfoPageService(mainHost, correlationID, accessToken);
      const response = infoPageService.fetchContext(slug);
      return response;
    }
  };

  let pageContext;
  try {
    pageContext = await fetchPageContext<InfoPageContext>(options);
    const host: string = get(context, 'req.headers.host', '');
    pageContext.common.isApp = host.indexOf('app.') === 0;
  } catch (err) {
    const status: number = get(err, 'response.status', 0);
    if (status === 404) {
      return {
        notFound: true
      };
    }
  }

  if (pageContext) {
    if (pageContext.common['set-cookie']) {
      pageContext.common['set-cookie'].map((cookie) => {
        res.setHeader('Set-Cookie', cookie);
      });
    }
  }

  return {
    props: {
      context: pageContext
    }
  };
};

export default InfoPage;
