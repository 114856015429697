import styles from './html-block.module.css';

import classNames from 'classnames';
import React from 'react';

interface HTMLBlockProps {
  className?: string;
  html: string;
}

export const HTMLBlock = (props: HTMLBlockProps) => {
  return <div className={classNames(styles.root, props.className)} dangerouslySetInnerHTML={{ __html: props.html }} />;
};
