import styles from './breadcrumbs.module.css';

import { Breadcrumb } from 'core/entities/breadcrumb';

import { BreadcrumbItem } from 'components/common/breadcrumbs/breadcrumb-item/breadcrumb-item';

interface BreadcrumbsProps {
  breadcrumbs: Array<Breadcrumb>;
}

export function Breadcrumbs({ breadcrumbs }: BreadcrumbsProps) {
  return (
    <nav>
      <ul className={styles.root} itemScope itemType='https://schema.org/BreadcrumbList'>
        {breadcrumbs.map((breadcrumb, index) => (
          <BreadcrumbItem key={breadcrumb.title} breadcrumb={breadcrumb} index={index} />
        ))}
      </ul>
    </nav>
  );
}
