import styles from './info-links-list.module.css';

import { InfoPageMeta } from 'core/entities/info';

import { InfoLinkItem } from 'components/info-page/info-links-list/info-link-item/info-link-item';

interface LinksListProps {
  links: Array<InfoPageMeta>;
}

export const InfoLinksList = ({ links }: LinksListProps) => (
  <ul className={styles.root}>
    {links.map((link) => (
      <InfoLinkItem key={link.slug} link={link} />
    ))}
  </ul>
);
